import { React, useState, useEffect } from "react";
import {
  Space,
  DatePicker,
  Select,
  Button,
  Form,
  Table,
  Progress,
  Spin,
  message
} from "antd";
import jQuery from "jquery";
import { EyeFilled } from "@ant-design/icons";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useAuth } from "src/context/auth-context";

const { Option } = Select;
const Workers = (props) => {
  const auth = useAuth();
  jQuery(document).ready(function () {
    const numberOfRows = jQuery(
      ".worker-info-wrapper .ant-table-wrapper.worker-table tbody.ant-table-tbody tr"
    ).length;
    if (numberOfRows < 10) {
      jQuery(
        ".worker-info-wrapper .ant-table-wrapper.worker-table ul.ant-pagination.ant-table-pagination.ant-table-pagination-right"
      ).css("display", "none");
    } else {
      jQuery(
        ".worker-info-wrapper .ant-table-wrapper.worker-table ul.ant-pagination.ant-table-pagination.ant-table-pagination-right"
      ).css("display", "flex");
    }
  });
  const siteID = useParams();
  var dateF;
  var dateU;
  const [workerInfo, setWorkerInfo] = useState([]);
  const [workerInfoTemp, setWorkerInfoTemp] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const workerInfoUrl = `${process.env.REACT_APP_DRUPAL_URL}/jsonapi/get_signed_toolbox_per_worker?construction_site=${props.siteID}`;
  const [initialLoad, setInitialLoad] = useState(true);
  const { tableData, columns } = getTableData(initialLoad);

  const onFinish = (values) => {
    setInitialLoad(false);
    let workerType = values.type;

    if (workerType === undefined) {
      workerType = 'all';
    }

    const dateFrom = values.dateFrom.format('YYYY-MM-DD');
    const dateFromTimeStamp = moment(dateFrom).format('X');
    let dateUntilTimeStamp = values.dateUntill;

    // If no until timestamp selected, we add 1 year.
    if (dateUntilTimeStamp === undefined) {
      dateUntilTimeStamp = moment().add("100", "year").format("X");
    }
    else {
      const dateUntil = values.dateUntill.format('YYYY-MM-DD');
      dateUntilTimeStamp = moment(dateUntil).format('X');
    }
    
    if (dateFromTimeStamp > dateUntilTimeStamp) {
      message.error("Start date should be smaller than end date")
      return;
    }

    const result = workerInfoTemp.filter(function checkDate(item) {
      // console.log("item", item);
      const dateCheckinTimeStamp = moment(item.field_check_in_date).format("X");
      // console.log("dateCheckinTimeStamp", dateCheckinTimeStamp);
      return (
        dateCheckinTimeStamp >= dateFromTimeStamp &&
        dateCheckinTimeStamp <= dateUntilTimeStamp &&
        (workerType === 'all' || workerType === item.field_type_checkin)
      );
    });

    setWorkerInfo(result);
    setWorkerInfoTemp(workerInfo);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFromChange = (date, dateString) => {
    // console.log(date, dateString);
    dateF = `${moment(dateString).format("X")}`;
  };

  const onUntillChange = (date, dateString) => {
    // console.log(date, dateString);
    dateU = `${moment(dateString).format("X")}`;

    if (dateF > dateU) {
      alert("Start date should be smaller than end date");
    }
  };

  const [form] = Form.useForm();

  const clearAllFilters = () => {
    form.resetFields();
    setWorkerInfo(workerInfoTemp);
    setWorkerInfoTemp(workerInfoTemp);
    setInitialLoad(true);
  };

  useEffect(() => {
    axios({
      url: workerInfoUrl,
      method: "get",
      auth: {
        username: sessionStorage.getItem("username"),
        password: auth.pass,
      },
    })
      .then((workerInfoResponse) => {
        setWorkerInfo(workerInfoResponse.data);
        setWorkerInfoTemp(workerInfoResponse.data);
        // console.log(workerInfoResponse.data);
        setIsLoaded(true);
      })
      .catch((workerInfoError) => {
        console.log(workerInfoError, "workerInfoError");
      });
  }, [workerInfoUrl,auth.pass]);

  return !isLoaded ? (
    <h1>
      <Spin size="large" />
    </h1>
  ) : (
    <div className="worker-info-wrapper">
      <Space direction="vertical">
        <Space className="worker-information">
          <h4>Workers</h4>
          <Link to={`/add-external-worker/${siteID.id}`}>
            <Button type="primary">Add Worker</Button>
          </Link>
        </Space>

        <Space className="worker-filter">
          <Form
            form={form}
            name="workersFilter"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="dateFrom"
              value={moment()}
              rules={[
                {
                  required: true,
                  message: "Please input start date!",
                },
              ]}
            >
              <DatePicker placeholder="From" onChange={onFromChange} value={moment()} defaultValue={moment()} />
            </Form.Item>
            <Form.Item
              name="dateUntill"
              rules={[
                {
                  required: false,
                  message: "Please input end date!",
                },
              ]}
            >
              <DatePicker placeholder="Untill" onChange={onUntillChange} />
            </Form.Item>

            <Form.Item
              name="type"
              rules={[
                {
                  required: false,
                  message: "Please select type!",
                },
              ]}
            >
              <Select placeholder="Type">
                <Option value="Checkin at work">Checkin at work</Option>
                <Option value="External">External</Option>
              </Select>
            </Form.Item>

            <Button type="primary" htmlType="submit">
              Filter
            </Button>

            <Button onClick={clearAllFilters}>Reset</Button>

          </Form>
        </Space>
      </Space>
      <hr />
      <Table
        dataSource={tableData}
        columns={columns}
        className="worker-table"
      />
    </div>
  );

  function getTableData(initial) {
    let results = workerInfo;
    
    // If initial load, filter results on today.
    if (initial) {
      const today = moment().format('YYYY-MM-DD');
      const todayTimeStamp = moment(today).format('X');
      results = workerInfo.filter(function checkDate(item) {
        const dateCheckinTimeStamp = moment(item.field_check_in_date).format("X");
        return dateCheckinTimeStamp >= todayTimeStamp;
      });
    }

    const tableData = results.map((item, index) => {
      const fullName = item.field_first_name + " " + item.field_naam;
      const companyName = item.field_company_name;
      const unique_Id = item.title;
      const per = Math.floor(item.signed);
      return {
        key: index,
        name: fullName,
        id: unique_Id,
        company_name: companyName,
        tool_sign: (
          <Progress
            percent={per}
            status={per < 50 ? "exception" : null}
            size="small"
          />
        ),
        action_view: (
          <Link
            to={`/worker-details/${unique_Id}`}
            state={{
              construction_site_id: props.siteID,
              adminTitle: props.adminTitle,
            }}
          >
            <EyeFilled />
          </Link>
        ),
      };
    });
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Company name",
        dataIndex: "company_name",
        key: "company_name",
      },
      {
        title: "Toolboxes signed",
        dataIndex: "tool_sign",
        key: "tool_sign",
      },
      {
        title: "Actions",
        dataIndex: "action_view",
        key: "action_view",
      },
    ];
    return { tableData, columns };
  }
};

export default Workers;
