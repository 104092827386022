import { React, Fragment, useState, useEffect } from "react";
import { Col, Row } from "antd";
import { Button, Form, Input, message, Select } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./AddExternalWorker.css";
import { useAuth } from "src/context/auth-context";

const AddExtWorker = () => {
  const location = useLocation();
  const data = location.state.data;
  const construction_site_id = location.state.construction_site_id;
  const { Option } = Select;
  const [componentDisabled, setComponentDisabled] = useState(true);
  const [submit, setSubmit] = useState(false);
  const nav = useNavigate();
  const auth = useAuth();
  useEffect(() => {
    if (data.type === "External") {
      setComponentDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FORM SUBMISSION
  const onFinish = (values) => {
    setSubmit(true);
    if (data.type === "Checkin at work") {
      const postData = [
        {
          name: values.id,
          type: values.type,
          language: values.language,
        },
      ];
      // const id = siteID.id;
      // console.log("Success:", values);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_DRUPAL_URL}/jsonapi/edit_worker`,
        headers: {
          "Content-Type": "text/plain",
        },
        auth: {
          username: sessionStorage.getItem("username"),
          password: auth.pass,
        },
        data: postData,
      })
        .then((postResponse) => {
          if (postResponse.status === 200) {
            message.success("Succesfully Updated Worker");
            nav(`/construction-sites-detail/${construction_site_id}`);
          } else {
            message.error("Ooops Something Went Wrong !!");
            setSubmit(false);
          }
        })
        .catch((err) => {
          console.log("Update Error", err);
          setSubmit(false);
        });
    } else {
      const postData = [
        {
          name: values.id,
          firstName: values.first_name,
          lastName: values.name,
          type: values.type,
          language: values.language,
        },
      ];
      axios({
        method: "post",
        url: `${process.env.REACT_APP_DRUPAL_URL}/jsonapi/edit_worker`,
        headers: {
          "Content-Type": "text/plain",
        },
        auth: {
          username: sessionStorage.getItem("username"),
          password: auth.pass,
        },
        data: postData,
      })
        .then((postResponse) => {
          if (postResponse.status === 200) {
            message.success("Succesfully Updated Worker");
            nav(`/construction-sites-detail/${construction_site_id}`);
          } else {
            message.error("Ooops Something Went Wrong !!");
            setSubmit(false);
          }
        })
        .catch((err) => {
          console.log("Update Error", err);
          setSubmit(false);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Fragment>
      <Row className="add-external-worker-wrapper update-external">
        <Col span={20}>
          <h1 className="worker-heading">Update Worker</h1>
        </Col>
        <Col span={20} className="middle-color">
          <p className="general-info">General Info</p>
          <Form
            name="addExternalWorker"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            {/*========================================== FORM ITEM =========================================== */}
            <Form.Item label="ID" name="id" initialValue={data.ISNZ}>
              <Input disabled={true} placeholder="Enter Phone" />
            </Form.Item>

            <Form.Item
              label="First Name:"
              name="first_name"
              initialValue={data.firstname}
            >
              <Input
                disabled={componentDisabled}
                placeholder="Enter First Name"
              />
            </Form.Item>

            <Form.Item label="Last Name:" name="name" initialValue={data.name}>
              <Input disabled={componentDisabled} placeholder="Enter Name" />
            </Form.Item>

            <Form.Item label="Type:" name="type" initialValue={data.type}>
              <Select
                style={{
                  width: 200,
                }}
                placeholder="N/L"
                disabled={true}
              >
                <Option value="Checkin at work">Checkin at work</Option>
                <Option value="External">External</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Preferred Language"
              name="language"
              initialValue={data.language}
            >
              <Select
                style={{
                  width: 200,
                }}
              >
                <Option value="Engels">Engels</Option>
                <Option value="Frans">Frans</Option>
                <Option value="Nederlands">Nederlands</Option>
                <Option value="Pools">Pools</Option>
                <Option value="Portugees">Portugees</Option>
                <Option value="Roemeens">Roemeens</Option>
              </Select>
            </Form.Item>
            <Form.Item
              className="button-wrapper"
              wrapperCol={{
                offset: 22,
                span: 2,
              }}
            >
              <Button loading={submit} type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AddExtWorker;
